
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

@font-face {
	font-family: 'Manrope-ExtraLight'; 
	src: url(../src/fonts/Manrope-ExtraLight.ttf); 
}

@font-face {
	font-family: 'Manrope-Light'; 
	src: url(../src/fonts/Manrope-Light.ttf); 
}

@font-face {
	font-family: 'Manrope-Regular'; 
	src: url(../src/fonts/Manrope-Regular.ttf); 
}

@font-face {
	font-family: 'Manrope-Medium'; 
	src: url(../src/fonts/Manrope-Medium.ttf); 
}

@font-face {
	font-family: 'Manrope-Semibold'; 
	src: url(../src/fonts/Manrope-SemiBold.ttf); 
}

@font-face {
	font-family: 'Manrope-Bold'; 
	src: url(../src/fonts/Manrope-Bold.ttf); 
}

@font-face {
	font-family: 'Manrope-Extrabold'; 
	src: url(../src/fonts/Manrope-ExtraBold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Light'; 
	src: url(../src/fonts/Montserrat-Light.ttf); 
}

@font-face {
	font-family: 'Montserrat-Regular'; 
	src: url(../src/fonts/Montserrat-Regular.ttf); 
}

@font-face {
	font-family: 'Montserrat-Medium'; 
	src: url(../src/fonts/Montserrat-Medium.ttf); 
}

@font-face {
	font-family: 'Montserrat-Semibold'; 
	src: url(../src/fonts/Montserrat-Semibold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Bold'; 
	src: url(../src/fonts/Montserrat-Bold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Extrabold'; 
	src: url(../src/fonts/Montserrat-Extrabold.ttf); 
}

body, body * {
    font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.logoheader {
    background: url(../src/img/img_top.svg) no-repeat center center;
    padding: 11px 8px 11px 8px;
    background-size: contain;
    height: 100%;
}

.logoheader_image {
    margin-top: 50px;
    background-size: contain;
    height: 100%;
    max-height: 300px;
}

.logoheader_image_end {
    background-size: contain;
    height: 100%;
}

.logoheader_positive {
    background: url(../src/img/positive.svg) no-repeat center center;
}

.logoheader_positive_end {
    background: url(../src/img/positive_down.svg) no-repeat center center;
}

.logoheader_negative {
    background: url(../src/img/negative.svg) no-repeat center center;
}

.logoheader_negative_end {
    background: url(../src/img/negative_down.svg) no-repeat center center;
}

.full_height {
    height: 100%;
}

.title_top {
    color: #ffffff;
    font-size: 25px;
    font-family: "Montserrat-Medium" !important;
    padding: 10px;
    text-align: center;
}

.end_title_1, .end_title_2 {
    color: #494949;
    text-align: center;
}

.end_title_1 {
    font-family: "Montserrat-Bold" !important;
    font-size: 30px;
}

.end_title_2 {
    font-size: 20px;
}

.end_button {
    margin-top: auto;
    margin-bottom: 20px;
}

.end_center {
    margin: auto;
}

.widthfull {
    width: 100%;
    max-width: 600px;
}

.ant-radio-inner {
    background: #e8eaef;
    border-color: transparent !important;
    -webkit-box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.2);
    height: 20px;
    width: 20px;
}

.full_end {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #ffffff;
}

.top_end {
    height: 100vh;
    max-height: 70vh;
    padding: 11px;
    background: #ffffff;
    border-radius: 0 0 50px 50px;
    -webkit-box-shadow: 0px 10px 14px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 10px 14px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 10px 14px 0px rgba(34, 60, 80, 0.2);
}

.bottom_end {
    height: 100vh;
    max-height: 30vh;
    padding: 30px;
}

.ant-radio-inner:hover {
    -webkit-box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.3);
    -moz-box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.3);
    box-shadow: 2px 0px 4px 0px rgba(34, 60, 80, 0.3);
}

.ant-radio-checked .ant-radio-inner::after {
    background: rgb(132,204,255);
    background: linear-gradient(90deg, rgba(132,204,255,1) 0%, rgba(89,200,223,1) 100%);
}

.ant-radio-inner::after {
    top: 5px;
    left: 5px;
    transform: scale(1);
    opacity: 1;
    background: #d4d7d8;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: transparent !important;
}

.body_full {
    background: url(../src/img/circle_2.svg) no-repeat, #ffffff;
    background-position-x: right;
    background-position-y: center;
    background-size: 100px;
    border-radius: 50px 50px 0 0;
    padding: 20px 20px 0 20px;
    margin-top: auto;
    max-height: calc(75vh - 72px - 100px);
    flex-grow: 1;
}

.body_container {
    display: flex;
    flex-direction: column;
    overflow: auto !important;
    max-height: calc(75vh - 212px);
}

.body_container > * {
    margin-bottom: 10px;
    display: table;
}

.ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.headerfull {
    height: 100vh;
    max-height: 25vh;
    padding: 11px;
}

.mainbody {
    height: 100vh;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.des-button {
    background: rgb(132,204,255);
    background: linear-gradient(90deg, rgba(132,204,255,1) 0%, rgba(89,200,223,1) 100%);
    color: #ffffff;
    border: none;
    height: 42px;
    font-size: 16px;
    padding: 4px 36px;
}

.bg_bottom {
    background: url(../src/img/circle_1.svg) no-repeat, #ffffff;
    background-position-y: 5px;
    background-position-x: -5px;
}

.des-button > * {
    font-family: 'Manrope-Bold' !important;
}

.des-button:hover, .des-button:focus {
    background: rgb(117,198,255);
    background: linear-gradient(90deg, rgba(117,198,255,1) 0%, rgba(77,204,231,1) 100%) !important;
    color: #ffffff !important;
}

.des-divider {
    height: 2px;
    width: 100%;
    background: #e8e8e8;
    margin: 5px 0;
}

.fcolumn {
    display: flex;
    flex-direction: column;
}

.flexcenter {
    display: flex;
    justify-content: center;
}

.logo {
    height: 40px;
    background-size: contain;
}

.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #001a29;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #002438;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.nomargin {
    margin:0 !important
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.maxtask {
    margin-top: -20px;
}

.max400 {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:30px;
    position:relative;
    top:180px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe087 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffecf6 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.ant-layout {
    background: rgb(132,204,255);
    background: linear-gradient(90deg, rgba(132,204,255,1) 0%, rgba(89,200,223,1) 100%);
}

.lasttask {
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
}

.theme {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.elementlock::before {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
}

.elementlock ~ * {
    z-index: 3;
}

.ant-table {
    background: transparent;
}

.taskhide {
    cursor: pointer;
    transition: all .3s ease;
}

.title_item {
    color: #494949 !important;
}

.title_item {
    font-size: 19px !important;
}

.ant-form label {
    font-size: 18px;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.badgemenu {
    margin-right: 5px;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.taskhide:hover {
    -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    transform: scale(1.01);
}

.ant-popover-inner {
    border-radius: 15px;
}

.ant-alert {
    border-radius: 20px !important;
}

.fcenter {
    display: flex;
    justify-content: center;
}

.shadowscreen {
    -webkit-animation: shadowscreen 3s infinite ease-in-out;
    animation: shadowscreen 3s infinite ease-in-out;
    background: #f7f7f7;
    border-radius: 30px;
}

@keyframes shadowscreen {
    0% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
    50% {
        -webkit-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.login-form-button {
    width: 100%;
}

.authform {
    justify-content: center;
}

.ant-avatar > img, .ant-avatar .ant-image-img {
    border-radius: 50%;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.png) fixed no-repeat center center;
    background: #26272f !important;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

@media screen and (max-width: 700px) {
    .wcol:last-child:first-child {
        max-width: 100%;
    }
}

@media screen and (max-width: 650px) {

    .bg-auth {
        background: url(../src/img/bgm.png) fixed no-repeat center center;
    }
    
}

@media screen and (max-width: 600px) {

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    padding: 24px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authtitle {
    font-family: 'BNR';
    text-align: center;
    color: #FFFFFF;
}

.authtitle p:nth-child(2) {
    font-size: 35px;
    margin: 0 !important;
    padding: 0 !important;
}

.authtitle p:nth-child(3) {
    font-size: 60px;
    line-height: 40px;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
}

.inauths {
    border-radius: 25px;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #000000;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
}

.ant-pagination-item {
    border-radius: 50px;
}


.bold {
    font-weight: 800;
}

.ant-input, .ant-input-affix-wrapper, .ant-input-number, .ant-modal-content, .ant-picker, .ant-popover-buttons button, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector {
    border-radius: 30px!important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.wowload {
    -webkit-animation: wowload 1s ease;
    animation: wowload 1s ease;
}

@keyframes wowload {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

.column900 {
    align-items: center;
}

.column900 > .ant-col {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-col:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.ant-select-dropdown {
    border-radius: 15px;
    padding: 0;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffbcbc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
::-webkit-scrollbar {
    width: 5px;
}
  
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(132,204,255);
    background: linear-gradient(90deg, rgba(132,204,255,1) 0%, rgba(89,200,223,1) 100%);
}
  
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #e0e0e0;
}